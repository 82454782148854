var CopyType = (function () {
  // Variables

  var $element = '.btn-type-clipboard',
    $btn = $($element);

  // Methods

  function init($this) {
    $this.tooltip().on('mouseleave', function () {
      // Explicitly hide tooltip, since after clicking it remains
      // focused (as it's a button), so tooltip would otherwise
      // remain visible until focus is moved away
      $this.tooltip('hide');
    });

    var clipboard = new ClipboardJS($element);

    clipboard.on('success', function (e) {
      $(e.trigger)
        .attr('title', 'Copied!')
        .tooltip('_fixTitle')
        .tooltip('show')
        .attr('title', 'Copy to clipboard')
        .tooltip('_fixTitle');

      e.clearSelection();
    });
  }

  // Events
  if ($btn.length) {
    init($btn);
  }
})();
