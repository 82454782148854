//
// Tags input
//

'use strict';

var Tags = (function () {
  //
  // Variables
  //

  var $tags = $('[data-toggle="tags"]');

  //
  // Methods
  //

  function init($this) {
    var options = {
      tagClass: 'badge badge-primary',
    };

    $this.tagsinput(options);
  }

  //
  // Events
  //

  if ($tags.length) {
    // Init selects
    $tags.each(function () {
      init($(this));
    });
  }
})();
