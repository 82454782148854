//
// Tooltip
//

'use strict';

var Tooltip = (function () {
  // Variables

  var $tooltip = $('[data-toggle="tooltip"]');

  // Methods

  function init() {
    $tooltip.tooltip();
  }

  // Events

  if ($tooltip.length) {
    init();
  }
})();
