//
// Forms
//

'use strict';

//
// Form control
//

var FormControl = (function () {
  // Variables

  var $input = $('.form-control'),
    $indeterminateCheckbox = $('[data-toggle="indeterminate"]');

  // Methods

  function init($this) {
    $this
      .on('focus blur', function (e) {
        $(this)
          .parents('.form-group')
          .toggleClass('focused', e.type === 'focus');
      })
      .trigger('blur');
  }

  // Events

  if ($input.length) {
    init($input);
  }

  // Add indeterminate state to a checkbox
  if ($indeterminateCheckbox.length) {
    $indeterminateCheckbox.each(function () {
      $(this).prop('indeterminate', true);
    });
  }
})();

//
// Custom input file
//

var CustomInputFile = (function () {
  // Variables

  var $customInputFile = $('.custom-input-file');

  // Methods

  function change($input, $this, $e) {
    var fileName,
      $label = $input.next('label'),
      labelVal = $label.html();

    if ($this && $this.files.length > 1) {
      fileName = ($this.getAttribute('data-multiple-caption') || '').replace(
        '{count}',
        $this.files.length
      );
    } else if ($e.target.value) {
      fileName = $e.target.value.split('\\').pop();
    }

    if (fileName) {
      $label.find('span').html(fileName);
    } else {
      $label.html(labelVal);
    }
  }

  function focus($input) {
    $input.addClass('has-focus');
  }

  function blur($input) {
    $input.removeClass('has-focus');
  }

  // Events

  if ($customInputFile.length) {
    $customInputFile.each(function () {
      var $input = $(this);

      $input.on('change', function (e) {
        var $this = this,
          $e = e;

        change($input, $this, $e);
      });

      // Firefox bug fix
      $input
        .on('focus', function () {
          focus($input);
        })
        .on('blur', function () {
          blur($input);
        });
    });
  }
})();
